import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { selectedCat, selectedDoctor } from '../../store/actions/categoryActions';
import { useTranslation } from 'react-i18next';

function Doctors({...others}) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const customization = useSelector((state) => state.customization);
   const {t} = useTranslation();
   const navigate = useNavigate();
  const onSubmit = (cat) => {
    dispatch(selectedDoctor(cat?._id, navigate))
  }
  return (
    <>
    <MinimalLayout>
    <Container className='listcontainer'>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
         <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 50}}/>
       <h2 className='quicklineminititle'>{t("Choose your doctor")}</h2>
       </div>
       <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 50}}>
      {Array.isArray(category?.doctors) && category?.doctors?.map((cat) => {
        return (
            <div onClick={() => {onSubmit(cat)}} style={{width: '100%', height: 50, marginBottom: 20, borderRadius: 5, border: '1px solid grey', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div style={{width: 40, height: 40, borderRadius: 20, marginLeft: 10,  overflow: 'hidden', backgroundColor: '#eeeeee'}}>
            <img style={{objectFit: 'cover', width: '100%', height: '100%', display: 'block'}} src={cat.staffphoto} alt='doc'/>
          </div>
          <div style={{display: 'flex', flexDirection: 'column',marginLeft: 10}}>
                <span style={{color: '#000', fontWeight: 'bold', fontSize: 14}}>Dr {cat.name}</span>
                <span style={{color: '#000', fontSize: 12}}>{cat.service.name}</span>
            </div>
        </div>
        )
      })}

       </div>
    </Container>
    </MinimalLayout>
      
    </>
  );
}

export default Doctors;