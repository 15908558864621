import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import MinimalLayout from '../../layout/MinimalLayout';
import { useTranslation } from 'react-i18next';

// Importing React-Bootstrap-Icons
import { CheckCircle, Calendar, Clock, People, CameraVideo, QuestionCircle, Translate, Power } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/actions/authActions'; // Import the logout action

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [showLanguageDialog, setShowLanguageDialog] = useState(false);
  const [showSupportDialog, setShowSupportDialog] = useState(false); // State for support modal

  const buttons = [
    { label: 'Check-In', icon: <CheckCircle size={40} />, link: '/organizations?type=checkin' },
    { label: 'Appointment', icon: <Calendar size={40} />, link: '/organizations?type=appointment' },
    { label: 'Recent Appointments', icon: <Clock size={40} />, link: '/recentappointments' },
    { label: 'Visits', icon: <People size={40} />, link: '/calls' },
    { label: 'Virtual Consultation', icon: <CameraVideo size={40} />, link: '/video-therapy' },
    {
      label: 'Support',
      icon: <QuestionCircle size={40} />,
      onClick: () => setShowSupportDialog(true), // Open support modal
    },
    {
      label: 'Language',
      icon: <Translate size={40} />,
      onClick: () => setShowLanguageDialog(true), // Open language selection modal
    },
    {
      label: 'Log Out',
      icon: <Power size={40} />,
      onClick: () => dispatch(logout(navigate)), // Logout action
    },
  ];

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setShowLanguageDialog(false);
  };

  return (
    <MinimalLayout>
      <Container className="text-center mt-4">
        <h4 className="mb-4">{t('Home')}</h4>
        <Row>
          {buttons.map((button, index) => (
            <Col xs={6} md={3} className="mb-3" key={index}>
              {button.link ? (
                <Link to={button.link} style={{ textDecoration: 'none' }}>
                  <Button
                    className="w-100 py-3 d-flex flex-column align-items-center justify-content-center"
                    style={{ height: '180px', backgroundColor: '#25A18E' }}
                  >
                    {button.icon}
                    <span className="mt-4">{t(button.label)}</span>
                  </Button>
                </Link>
              ) : (
                <Button
                  className="w-100 py-3 d-flex flex-column align-items-center justify-content-center"
                  style={{ height: '180px', backgroundColor: '#25A18E' }}
                  onClick={button.onClick}
                >
                  {button.icon}
                  <span className="mt-4">{t(button.label)}</span>
                </Button>
              )}
            </Col>
          ))}
        </Row>
      </Container>

      {/* Language Selection Modal */}
      <Modal show={showLanguageDialog} onHide={() => setShowLanguageDialog(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Choose Language')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Button
            variant="primary"
            className="m-2"
            onClick={() => handleLanguageChange('en')}
          >
            English
          </Button>
          <Button
            variant="success"
            className="m-2"
            onClick={() => handleLanguageChange('sw')}
          >
            Kiswahili
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLanguageDialog(false)}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Support Modal */}
      <Modal show={showSupportDialog} onHide={() => setShowSupportDialog(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Information')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            'Experiencing issues with our app? Call our support hotline at +255 753 909 090. We\'re here to assist you 24/7!'
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSupportDialog(false)}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </MinimalLayout>
  );
}

export default Home;
