import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import MinimalLayout from '../../layout/MinimalLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories, getDoctors, getOrganizations, selectedCat, selectedType } from '../../store/actions/categoryActions';
import { useNavigate, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

function Organizations({ ...others }) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const auth = useSelector((state) => state.auth);
  const [organization, setOrganization] = useState('');
  const [hasInsurance, setHasInsurance] = useState('');
  const [insurance, setInsurance] = useState('');
  const [cat, setCat] = useState('');
  const [type, setType] = useState('');
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    if (type) {
      setType(type);
    }
  }, [location.search]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (organization) {
      const choosenOrg = category?.organizations?.find((org) => org?._id === organization);
      dispatch(getCategories(choosenOrg, auth.token, navigate));
    }
  }, [organization, category?.organizations, auth.token, navigate, dispatch]);

  useEffect(() => {
    dispatch(getOrganizations(navigate));
  }, [dispatch, navigate]);

  const handleCategoryChange = (catId) => {
    setCat(catId);
    setErrors((prev) => ({ ...prev, cat: '' })); // Clear category error
    const selectedCategory = category?.categories?.find((cat) => cat?._id === catId);
    if (selectedCategory) {
      dispatch(selectedCat(selectedCategory, navigate));
    }
  };

  const onChangeInsurance = (val) => {
    setHasInsurance(val);
    setErrors((prev) => ({ ...prev, hasInsurance: '' })); // Clear insurance error
    dispatch(selectedType(val === 'yes' ? 'insurance' : 'cash'));
  };

  const validateForm = () => {
    const formErrors = {};
    if (!organization) formErrors.organization = t('Please choose an organization.');
    if (!cat) formErrors.cat = t('Please choose a category.');
    if (hasInsurance === '') formErrors.hasInsurance = t('Please specify if you have insurance.');
    if (hasInsurance === 'yes' && !insurance) formErrors.insurance = t('Please choose your insurance.');
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const onSubmit = () => {
    if (!validateForm()) return;

    if (type === 'checkin') {
      navigate('/qrcode');
    } else {
      dispatch(getDoctors(organization, category?.selectedcategory?._id, navigate));
      navigate('/doctors');
    }
  };

  return (
    <>
      <MinimalLayout>
        <Container className="listcontainer">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src="https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png"
              style={{ width: 100 }}
              alt="Logo"
            />
            <h2 className="quicklineminititle">{t('Choose your Organization')}</h2>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <Form.Group style={{ width: '100%' }}>
              <Form.Select
                size="md"
                style={{ width: '100%' }}
                value={organization}
                placeholder={t('Choose Organization')}
                onChange={(e) => {
                  setOrganization(e.target.value);
                  setErrors((prev) => ({ ...prev, organization: '' })); // Clear organization error
                }}
                isInvalid={!!errors.organization}
              >
                <option value="" disabled>
                  {t('Choose Organization')}
                </option>
                {Array.isArray(category.organizations) &&
                  category?.organizations?.map((org) => (
                    <option value={org?._id} key={org?._id}>
                      {org?.name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.organization}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group style={{ width: '100%', marginTop: 10 }}>
              <Form.Select
                size="md"
                style={{ width: '100%' }}
                placeholder={t('Choose Category')}
                value={cat}
                onChange={(e) => handleCategoryChange(e.target.value)}
                disabled={!organization}
                isInvalid={!!errors.cat}
              >
                <option value="" disabled>
                  {t('Choose Category')}
                </option>
                {Array.isArray(category.categories) &&
                  category?.categories?.map((cat) => (
                    <option value={cat?._id} key={cat?._id}>
                      {cat?.name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.cat}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group style={{ width: '100%', marginTop: 10 }}>
              <Form.Select
                size="md"
                style={{ width: '100%' }}
                value={hasInsurance}
                placeholder={t('Do you have Insurance?')}
                onChange={(e) => onChangeInsurance(e.target.value)}
                disabled={!cat}
                isInvalid={!!errors.hasInsurance}
              >
                <option value="" disabled>
                  {t('Do you have Insurance?')}
                </option>
                <option value="yes">{t('Yes')}</option>
                <option value="no">{t('No')}</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.hasInsurance}</Form.Control.Feedback>
            </Form.Group>

            {hasInsurance === 'yes' && (
              <Form.Group style={{ width: '100%', marginTop: 10 }}>
                <Form.Select
                  size="md"
                  style={{ width: '100%' }}
                  value={insurance}
                  placeholder={t('Choose Insurance')}
                  onChange={(e) => {
                    setInsurance(e.target.value);
                    setErrors((prev) => ({ ...prev, insurance: '' })); // Clear insurance error
                  }}
                  isInvalid={!!errors.insurance}
                >
                  <option value="" disabled>
                    {t('Choose Insurance')}
                  </option>
                  {Array.isArray(category.insurances) &&
                    category?.insurances?.map((ins) => (
                      <option key={ins._id} value={ins._id}>
                        {ins.name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.insurance}</Form.Control.Feedback>
              </Form.Group>
            )}

            <Button onClick={() => onSubmit()} variant="primary" style={{ width: '100%', marginTop: 20 }}>
              {t('Endelea')}
            </Button>
          </div>
        </Container>
        <CircleSpinnerOverlay
          loading={category.loading === 'getorganizations' || category.loading === 'getcategories'}
          overlayColor="rgba(0,153,255,0.2)"
        />
      </MinimalLayout>

      <div
        onClick={handleShow}
        style={{
          position: 'absolute',
          top: 10,
          right: 20,
          width: 100,
          height: 50,
          borderRadius: 30,
          backgroundColor: '#0080FF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span style={{ color: '#fff' }}>{t('Support')}</span>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Information')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            'Experiencing issues with our app? Call our support hotline at +255 753 909 090. We\'re here to assist you 24/7!'
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Organizations;
