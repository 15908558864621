import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { bookAppointment, updateAppointment } from '../../store/actions/categoryActions';
import { Link, useNavigate } from 'react-router-dom';
import { selectedCat } from '../../store/actions/categoryActions';
import moment from 'moment';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { useTranslation } from 'react-i18next';

function Timeslots({...others}) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const auth = useSelector((state) => state.auth);
  const customization = useSelector((state) => state.customization);
  const {t} = useTranslation();
  const navigate = useNavigate();

  const timeslots = () => {
    const startTime = moment('07:00 am', 'HH:mm a');
    const endTime = moment('10:00 pm', 'HH:mm a');
  
    const timeSlots = [];
  
    let currentTime = moment(startTime);
  
    let slot_id = 1; // Initialize slot_id
  
    while (currentTime.isBefore(endTime)) {
      const from = currentTime.format('HH:mm a');
      const to = currentTime.add(15, 'minutes').format('HH:mm a');
  
      timeSlots.push({ slot_id, from, to });
      slot_id++; // Increment slot_id for the next slot
    }
  
    return timeSlots;
  };

 const combineDateAndTimeToISOString = (dateStr, timeStr) => {
  // Parse the date and time
  const dateMoment = moment(dateStr, 'YYYY-MM-DD');
  const timeMoment = moment(timeStr, 'h:mma');

  // Combine the date and time
  const combinedMoment = dateMoment.set({
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
  });

  // Format to ISO string
  const isoString = combinedMoment.toISOString();

  return isoString;
};

const filteredTimeSlots = timeslots().filter(slot => {
    return !category.timeslots.some(existingSlot => parseInt(existingSlot.slot_id) === slot.slot_id);
  });

  const bookappointment = (time) => {
    const fromtimeiso = combineDateAndTimeToISOString(category.selecteddate, time?.from);
    const totimeiso = combineDateAndTimeToISOString(category.selecteddate, time?.to);
     if(category?.selectedtype === 'recent'){
      dispatch(updateAppointment({from: fromtimeiso, to: totimeiso}, time.slot_id, category.appointment?._id, auth.userdata.auth_token, category.language, navigate))
     }else{
      if(category.selectedtype === 'insurance'){
        dispatch(bookAppointment(category.selectedcategory?._id || category.selectedcategory, category.organization.officephonenumber, {from: fromtimeiso, to: totimeiso}, time.slot_id, auth.userdata.user.telephone_number, auth.userdata.user.full_name, category.organization._id, auth?.userdata?.user?._id, category.selectedtype, '', category.selecteddoctor, auth.userdata.auth_token, category.language, category.selectedinsurance, navigate))
       }else{
       dispatch(bookAppointment(category.selectedcategory?._id || category.selectedcategory, category.organization.officephonenumber, {from: fromtimeiso, to: totimeiso}, time.slot_id, auth.userdata.user.telephone_number, auth.userdata.user.full_name, category.organization._id, auth.userdata?.user?._id, category.selectedtype, '', category.selecteddoctor, auth.userdata.auth_token, category.language, '', navigate));
       }
     }
   }

   const currentHour = new Date().getHours(); // Get the current hour
const currentDate = new Date();

// Function to extract the date part (yyyy-mm-dd) from a Date object
const getDateOnly = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
  return (
    <>
    <MinimalLayout>
    <Container className='listcontainer'>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
         <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 100}}/>
       <h2 className='quicklineminititle'>{t("Choose your time slot")}</h2>
       </div>
       <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'wrap' }}>
       {/* {filteredTimeSlots?.map((cat) => {
            return (
          <Button onClick={() => {bookappointment(cat)}} variant="outline-primary" key={cat?._id} style={{width: '40%', marginTop: 20}}> {cat.from} - {cat.to}</Button>
        )
      })} */}
      {
  filteredTimeSlots
    ?.filter((time) => {
      const fromHour = parseInt(time?.from?.split(':')[0], 10); // Extract the hour from 'from' time
      const slotDate = new Date(category?.selecteddate); // Get the slot date as a string

      // Skip the filter if the slot date is different from the current date
      if (getDateOnly(new Date(moment(slotDate).subtract(1, 'days'))) !== getDateOnly(currentDate)) {
        console.log('yes')
        return true; // Return true to include the time slot in the result
      }

      // Filter out slots that start before the current hour
      return fromHour >= currentHour;
    })
    .map((time) => (
      <Button onClick={() => { bookappointment(time) }} variant="outline-primary" key={time?._id} style={{ width: '40%', marginTop: 20 }}> {time.from} - {time.to}</Button>
      // <TouchableOpacity style={styles.button} marginBottom={5} onPress={() => bookappointment(time)}>
      //   <Text style={styles.buttontext}>{time.from} - {time.to}</Text>
      // </TouchableOpacity>
    ))
    // If the conditions are not met, return all the data without filtering
    ?? filteredTimeSlots.map((time) => (
      <Button onClick={() => { bookappointment(time) }} variant="outline-primary" key={time?._id} style={{ width: '40%', marginTop: 20 }}> {time.from} - {time.to}</Button>
      // <TouchableOpacity style={styles.button} marginBottom={5} onPress={() => bookappointment(time)}>
      //   <Text style={styles.buttontext}>{time.from} - {time.to}</Text>
      // </TouchableOpacity>
    ))
}

       </div>
    </Container>
    <CircleSpinnerOverlay
      　　loading={category.loading === 'appointment'} 
       overlayColor="rgba(0,153,255,0.2)"
      />
    </MinimalLayout>
      
    </>
  );
}

export default Timeslots;