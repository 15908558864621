import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AuthLogin from '../components/login';
import Counter from '../components/counter';
import Appointment from '../components/appointment';
import Categories from '../components/categories';
import Todaysreport from '../components/reports/todaysreport';
import Monthlyreport from '../components/reports/monthlyreport';
import Categoryreport from '../components/reports/categoryreport';
import Staffperformance from '../components/reports/staffperformance';
import Staff from '../components/staff';
import Staffcreate from '../components/staff/create';
import Editprofile from '../components/profile/view';
import Changepassword from '../components/profile/changepassword';
import Queue from '../components/link/queue';
import Bookingdetails from '../components/link/bookingdetails';
import Mobile from '../components/mobile';
import Verifyphone from '../components/verifyphone';
import Personalinformation from '../components/personalinformation';
import Addorg from '../components/organization/add';
import { useSelector } from 'react-redux';
import Call from '../components/call';
import Dashboard from '../components/dashboard';
import Tickets from '../components/tickets';
import Qrcode from '../components/qrcode';
import Display from '../components/display';
import Editcreate from '../components/staff/edit';
import Weeklyreport from '../components/reports/weeklyreports';
import Insurance from '../components/insurance';
import Organizations from '../components/organizations';
import Choicescreen from '../components/choicescreen';
import Insurancechoice from '../components/insurancechoice';
import Ticketsuccess from '../components/ticketsuccess';
import Doctors from '../components/doctors';
import Calender from '../components/calender';
import Timeslots from '../components/timeslots';
import Successscreen from '../components/successscreen';
import Recentappointments from '../components/recentappointments';
import { useTranslation } from 'react-i18next';
import Home from '../components/Home';
import Visits from '../components/calls';

export default function ThemeRoutes() {
  const authentication = useSelector((state) => state.auth);
  const category = useSelector((state) => state.category);
  const isAuthenticated = authentication.isAuthenticated ;
  const {i18n} = useTranslation();
  const navigate = useNavigate();
  const NavigateToLogin = () => {
    navigate('/auth/login');
    return null;
  };

  const NavigateToDashboard = () => {
    navigate('/home');
  };

  useEffect(() => {
    console.log(category.language);
    i18n.changeLanguage(category.language === 'english' ? 'en' : 'sw');
  }, [i18n, category.language]);


  return (
    <div>
      <Routes>
        <Route path="/auth/login" element={isAuthenticated ? <NavigateToDashboard/> : <AuthLogin />}/>
        <Route path="/organizations" element={!isAuthenticated ? <NavigateToLogin/> : <Organizations />}/>
        <Route path="/recentappointments" element={!isAuthenticated ? <NavigateToLogin/> : <Recentappointments />}/>
        <Route path="/calls" element={!isAuthenticated ? <NavigateToLogin/> : <Visits />}/>
        <Route path="/choicescreen" element={!isAuthenticated ? <NavigateToLogin/> : <Choicescreen />}/>
        <Route path="/doctors" element={!isAuthenticated ? <NavigateToLogin/> : <Doctors />}/>
        <Route path="/timeslots" element={!isAuthenticated ? <NavigateToLogin/> : <Timeslots />}/>
        <Route path="/successscreen" element={!isAuthenticated ? <NavigateToLogin/> : <Successscreen />}/>
        <Route path="/ticketsuccess" element={!isAuthenticated ? <NavigateToLogin/> : <Ticketsuccess />}/>
        <Route path="/insurancechoice" element={!isAuthenticated ? <NavigateToLogin/> : <Insurancechoice />}/>
        <Route path="/calender" element={!isAuthenticated ? <NavigateToLogin/> : <Calender />}/>
        <Route path="/auth/mobile" element={isAuthenticated ? <NavigateToDashboard/> : <Mobile />}/>
        <Route path="/auth/verifyphone" element={isAuthenticated ? <NavigateToDashboard/> : <Verifyphone />}/>
        <Route path="/auth/personalinformation" element={isAuthenticated ? <NavigateToDashboard/> : <Personalinformation />}/>
        <Route path="/counter" element={!isAuthenticated ? <NavigateToLogin/> :<Counter />}/>
        <Route path="/insurance" element={!isAuthenticated ? <NavigateToLogin/> :<Insurance />}/>
        <Route path="/appointment" element={!isAuthenticated ? <NavigateToLogin/> : <Appointment />}/>
        <Route path="/tickets" element={!isAuthenticated ? <NavigateToLogin/> : <Tickets />}/>
        <Route path="/category" element={!isAuthenticated ? <NavigateToLogin/> : <Categories />}/>
        <Route path="/qrcode" element={!isAuthenticated ? <NavigateToLogin/> : <Qrcode />}/>
        <Route path="/home" element={!isAuthenticated ? <NavigateToLogin/> : <Home />}/>
          {/* <Route path="login" element={<AuthLogin />} /> */}
          {/* <Route path="mobile" element={<AuthMobile />} />
          <Route path="verifyphone" element={<AuthMobileVerify />} />
          <Route path="personalinformation" element={<AuthRegister />} /> */}
        {/* </Route> */}
        <Route path="/" element={!isAuthenticated ? <NavigateToLogin/> : <Home />}>
          {/* <Route path="dashboard" element={<DashboardDefault />} /> */}
          <Route path="categories" element={<Categories />} />
          <Route path="qrcode" element={<Qrcode />} />
          {/* <Route path="appointments" element={<Appointments />} />
          <Route path="counters" element={<Counters />} /> */}
          {/* <Route path="editprofile" element={<Editprofile />} />
          <Route path="editpassword" element={<Editpassword />} /> */}
          <Route path="staff" element={<Staff />} />
          <Route path="staff/create" element={<Staffcreate />} />
          {/* <Route path="reports/today" element={<Dailyreport />} /> */}
          {/* <Route path="reports/monthly" element={<Monthlyreports />} /> */}
          <Route path="reports/staffperformance" element={<Staffperformance />} />
          <Route path="reports/category" element={<Categoryreport />} />
        </Route>
        {/* <Route path="/organization" element={isAuthenticated ? <MinimalLayout /> : <NavigateToLogin />}>
          <Route path="add" element={<Addorganization />} />
        </Route> */}
        {/* <Route path="/link" element={isAuthenticated ? <MinimalLayout /> : <NavigateToLogin />}> */}
          {/* <Route path="userdetails" element={<Appointmentuser />} /> */}
          {/* <Route path="bookingdetails" element={<Bookingdetails />} /> */}
          {/* <Route path="ticket" element={<Ticket />} /> */}
        {/* </Route> */}
        {/* <Route path="/calls" element={isAuthenticated ? <MinimalLayout><Calls/></MinimalLayout> : <NavigateToLogin />}>
        <Route path="center" element={<Calls />} />
        </Route> */}
        {/* <Route path="/about" exact component={About} /> */}
        {/* Other routes */}
      </Routes>
    </div>
  );
}
