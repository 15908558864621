import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Alert, Modal } from 'react-bootstrap';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import MinimalLayout from '../../layout/MinimalLayout';
import { Power } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories, getOrganizations, selectedCat } from '../../store/actions/categoryActions';
import { logout } from '../../store/actions/authActions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

function Organizations({ ...others }) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category); // To access organizations and categories
  const auth = useSelector((state) => state.auth); // To access auth token
  const [organization, setOrganization] = useState('');
  const [hasInsurance, setHasInsurance] = useState('');
  const [insurance, setInsurance] = useState('');
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Trigger fetching categories when organization changes
  useEffect(() => {
    if (organization) {
      const choosenOrg = category?.organizations?.find((org) => org?._id === organization);
      dispatch(getCategories(choosenOrg, auth.token, navigate));
    }
  }, [organization]);

  // Fetch organizations on component load
  useEffect(() => {
    dispatch(getOrganizations(navigate));
  }, []);

  // Dispatch selected category action
  const handleCategoryChange = (catId) => {
    const selectedCategory = category?.categories?.find((cat) => cat?._id === catId);
    if (selectedCategory) {
      dispatch(selectedCat(selectedCategory, navigate));
    }
  };

  const insuranceOptions = ['Insurance A', 'Insurance B', 'Insurance C', 'Insurance D']; // Add more as needed

  return (
    <>
      <MinimalLayout>
        <Container className="listcontainer">
          <span
            onClick={() => {
              dispatch(logout(navigate));
            }}
            style={{ marginTop: 20 }}
          >
            <Power size={20} /> Log out
          </span>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src="https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png"
              style={{ width: 100 }}
            />
            <h2 className="quicklineminititle">{t('Choose your Organization')}</h2>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {/* Choose Organization */}
            <Form.Select
              size="md"
              style={{ width: '100%' }}
              value={organization}
              placeholder={t('Choose Organization')}
              onChange={(e) => setOrganization(e.target.value)}
            >
              <option value="" disabled>
                {t('Choose Organization')}
              </option>
              {Array.isArray(category.organizations) &&
                category?.organizations?.map((org) => (
                  <option value={org?._id} key={org?._id}>
                    {org?.name}
                  </option>
                ))}
            </Form.Select>

            {/* Choose Categories */}
            <Form.Select
              size="md"
              style={{ width: '100%', marginTop: 10 }}
              placeholder={t('Choose Category')}
              onChange={(e) => handleCategoryChange(e.target.value)}
              disabled={!organization || !category.categories?.length} // Disable if no categories available
            >
              <option value="" disabled>
                {t('Choose Category')}
              </option>
              {Array.isArray(category.categories) &&
                category?.categories?.map((cat) => (
                  <option value={cat?._id} key={cat?._id}>
                    {cat?.name}
                  </option>
                ))}
            </Form.Select>

            {/* Do You Have Insurance? */}
            <Form.Select
              size="md"
              style={{ width: '100%', marginTop: 10 }}
              value={hasInsurance}
              placeholder={t('Do you have insurance?')}
              onChange={(e) => setHasInsurance(e.target.value)}
            >
              <option value="" disabled>
                {t('Do you have insurance?')}
              </option>
              <option value="Yes">{t('Yes')}</option>
              <option value="No">{t('No')}</option>
            </Form.Select>

            {/* Choose Insurance */}
            {hasInsurance === 'Yes' && (
              <Form.Select
                size="md"
                style={{ width: '100%', marginTop: 10 }}
                value={insurance}
                placeholder={t('Choose Insurance')}
                onChange={(e) => setInsurance(e.target.value)}
              >
                <option value="" disabled>
                  {t('Choose Insurance')}
                </option>
                {insuranceOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            )}

            {/* Submit Button */}
            <Button variant="primary" style={{ width: '100%', marginTop: 20 }}>
              {t('Endelea')}
            </Button>

            {/* Information Alert */}
            <Alert
              variant="danger"
              style={{ width: '100%', marginTop: 100, backgroundColor: '#0080FF', border: 0 }}
            >
              <Alert.Heading style={{ color: '#fff' }}>{t('Information')}</Alert.Heading>
              <p style={{ color: '#fff' }}>
                {t(
                  'If you want to reschedule your appointment please click the appointment button from the bottom right of the app'
                )}
              </p>
            </Alert>
          </div>
        </Container>
        <CircleSpinnerOverlay
          loading={category.loading === 'getorganizations' || category.loading === 'getcategories'}
          overlayColor="rgba(0,153,255,0.2)"
        />
      </MinimalLayout>

      {/* Support Button */}
      <div
        onClick={handleShow}
        style={{
          position: 'absolute',
          top: 10,
          right: 20,
          width: 100,
          height: 50,
          borderRadius: 30,
          backgroundColor: '#0080FF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span style={{ color: '#fff' }}>{t('Support')}</span>
      </div>

      {/* Support Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Information')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            'Experiencing issues with our app? Call our support hotline at +255 753 909 090. We\'re here to assist you 24/7!'
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Organizations;
