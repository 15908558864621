// action - state management
import * as actionTypes from '../actions';

export const initialState = {
  createcategorysuccess: false,
  updatecategorysuccess: false,
  deletecategorysuccess: false,
  loading: false,
  categories: [],
  organizations: [],
  selectedcategory: '',
  organization: {},
  selectedtype: '',
  bookedticket: {},
  insurances: [],
  selectedinsurance: '',
  doctors: [],
  selecteddoctor: '',
  type: '',
  selecteddate: '',
  timeslots: '',
  appointment: {},
  recentappointments: [],
  visits: [],
  language: 'english',
  mobile: false
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionTypes.DEVICETYPE:
      return {
        ...state,
        mobile: action.payload
      }
    case actionTypes.LANGUAGE:
      return {
        ...state,
        language: action.payload
      }
    case actionTypes.RECENTAPPOINTMENTS:
      return {
        ...state,
        recentappointments: action.payload
      }
    case actionTypes.VISITS:
      return {
          ...state,
          visits: action.payload
        }
    case actionTypes.SELECTEDAPPOINTMENT:
      return {
        ...state,
        type: 'appointment',
        organization: action.payload.organization,
        selectedcategory: action.payload.service,
        selectedtype: 'recent',
        selecteddoctor: action.payload.doctor,
        appointment: {_id: action.payload.appointment}
      }
    case actionTypes.TIMESLOTS:
      return {
        ...state,
        timeslots: action.payload
      }
      case actionTypes.APPOINTMENT:
        return {
          ...state,
          appointment: action.payload
        }
    case actionTypes.SELECTEDDATE:
      return {
        ...state,
        selecteddate: action.payload
      }
    case actionTypes.TYPE:
      return {
        ...state,
        type: action.payload
      }
    case actionTypes.SELECTEDDOCTOR:
      return {
        ...state,
        selecteddoctor: action.payload
      }
    case actionTypes.GETDOCTORSSSUCCESS:
      return {
        ...state,
        doctors: action.payload,
        type: 'appointment'
      }
    case actionTypes.SELECTEDINSURANCE:
      return {
        ...state,
        selectedinsurance: action.payload
      }
    case actionTypes.GETINSURANCES:
      return {
        ...state,
        insurances: action.payload
      }
    case actionTypes.SUBMITTICKET:
      return {
        ...state,
        bookedticket: action.payload
      }
    case actionTypes.SELECTEDTYPE:
      return {
        ...state,
        selectedtype: action.payload
      }
    case actionTypes.GETORGANIZATION:
      return {
        ...state,
        organization: action.payload
      }
    case actionTypes.GETORGANIZATIONSSUCCESS:
      return {
        ...state,
        organizations: action.payload
      }
    case actionTypes.SELECTEDCAT:
      return {
        ...state,
        selectedcategory: action.payload
      }
    case actionTypes.CLEARSUCCESS:
      return {
        ...state,
        createcategorysuccess: false,
        updatecategorysuccess: false,
        deletecategorysuccess: false,
        loading: false,
      }
    case actionTypes.CREATECATEGORYSUCCESS:
        return {
          ...state,
          createcategorysuccess: action.payload
        };
    case actionTypes.UPDATECATEGORYSUCCESS:
        return {
          ...state,
          updatecategorysuccess: action.payload
        };
    case actionTypes.DELETECATEGORYSUCCESS:
        return {
            ...state,
            deletecategorysuccess: action.payload
          };
    case actionTypes.GETCATEGORYSUCCESS:
          return {
            ...state,
            categories: action.payload.services
          };
    default:
      return state;
  }
};

export default categoryReducer;
