import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      'Enter Client details to Log in': 'Enter Client details to Log in',
      'Enter Quantity': 'Enter Quantity',
      'Login': 'Login',
      'Phone number': 'Phone number',
      'Phone Number': 'Phone Number',
      'Enter Phone Number': 'Enter Phone Number',
      'Enter your address': 'Enter your address',
      'Enter Personal Information': 'Enter Personal Information',
      'Enter First Name': 'Enter First Name',
      'Enter Last Name': 'Enter Last Name',
      'Enter Username': 'Enter Username',
      'Select Date of Birth': 'Select Date of Birth',
      'Pin': 'Pin',
      'Forgot Pin?': 'Forgot Pin?',
      'Enter Your Pin': 'Enter Your Pin',
      'Show': 'Show',
      'Hide': 'Hide',
      'Don`t have an account yet? Sign up': 'Don`t have an account yet? Sign up',
      'Sign Up': 'Sign Up',
      'Create Your Account': 'Create Your Account',
      'Enter your mobile number so we can text you a verification code.': 'Enter your mobile number so we can text you a verification code.',
      'Continue': 'Continue',
      'Available Balance': 'Available Balance',
      'Challenges': 'Challenges',
      'Coins': 'Coins',
      'Special Offers': 'Special Offers',
      'My Challenges': 'My Challenges',
      'See All': 'See All',
      'Visit Marketplace for challenges': 'Visit Marketplace for challenges',
      'Profile': 'Profile',
      'Leaderboard': 'Leaderboard',
      'Activity History': 'Activity History',
      'Activities': 'Activities',
      'How it Works': 'How it Works',
      'Support': 'Support',
      'Privacy Policy': 'Privacy Policy',
      'Terms and Conditions': 'Terms and Conditions',
      'Language': 'Language',
      'Sales Representative': 'Sales Representative',
      'Sales': 'Sales',
      'Representative': 'Representative',
      'Your Rewards': 'Your Rewards',
      'Personal Info': 'Personal Info',
      'Help Center': 'Help Center',
      'Edit Profile': 'Edit Profile',
      'Reset Pin': 'Reset Pin',
      'Delete Account': 'Delete Account',
      'Log out': 'Log out',
      'Gender': 'Gender',
      'Male': 'Male',
      'Female': 'Female',
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      'Username': 'Username',
      'Date of birth': 'Date of birth',
      'submit': 'submit',
      'alert': 'alert',
      'are you sure you want to delete your account?': 'are you sure you want to delete your account?',
      'how it works': 'how it works',
      'all': 'all',
      'Product Orders': 'Product Orders',
      'Shop Registration': 'Shop Registration',
      'Marketplace': 'Marketplace',
      'Accept Challenge': 'Accept Challenge',
      'expired': 'expired',
      'earn': 'earn',
      'points': 'points',
      'time': 'time',
      'Win': 'Win',
      'ABOUT THIS CHALLENGE': 'ABOUT THIS CHALLENGE',
      'product categories': 'product categories',
      'Products': 'Products',
      'Sell Products': 'Sell Products',
      'Make an order from uza outlets': 'Make an order from uza outlets',
      'Register Customers': 'Register Customers',
      'Register outlets to uza platform': 'Register outlets to uza platform',
      'Make a Survey': 'Make a Survey',
      'Make a survey to outlets': 'Make a survey to outlets',
      'Invite Friends': 'Invite Friends',
      'Invite users to uza platform': 'Invite users to uza platform',
      'Categories': 'Categories',
      'Search': 'Search',
      'Sort products': 'Sort Products',
      'featured': 'featured',
      'Low to High': 'Low to High',
      'High to Low': 'High to Low',
      'new arrivals': 'new arrivals',
      'in stock': 'in stock',
      'Choose a package': 'Choose a package',
      'Product description': 'Product Description',
      'Related Products': 'Related Products',
      'Checkout': 'Checkout',
      'Select Outlet': 'Select Outlet',
      'Cart is empty, please add items': 'Cart is empty, please add items',
      'Scan QrCode': 'Scan QrCode',
      'or': 'or',
      'Search for an Outlet': 'Search for an Outlet',
      'Enter Outlet`s Phone number': 'Enter Outlet`s Phone number',
      'Verify Phone number': 'Verify Phone number',
      'register customer': 'register customer',
      'outlet name': 'outlet name',
      'enter outlet name': 'enter outlet name',
      'outlet type': 'outlet type',
      'Select from list': 'Select from list',
      'outlet phone number': 'outlet phone number',
      'outlet address': 'outlet address',
      'contact person name': 'contact person name',
      'alternative phone number': 'alternative phone number',
      'tin number': 'tin number',
      'enter tin number': 'enter tin number',
      'comments': 'comments',
      'enter any comments': 'enter any comments',
      'Select upload method': 'Select upload method',
      'Take a photo from camera': 'Take a photo from camera',
      'Take photo from camera': 'Take photo from camera',
      'Choose photo from gallery': 'Choose photo from gallery',
      'Notifications': 'Notifications',
      'Pending Balance': 'Pending Balance',
      'Last Transaction': 'Last Transaction',
      'Withdraw': 'Withdraw',
      'Transaction History': 'Transaction History',
      'Withdraw Options': 'Withdraw Options',
      'Amount': 'Amount',
      'Proceed to checkout': 'Proceed to checkout',
      'requesting withdraw': 'requesting withdraw',
      'Enter Withdraw details': 'Enter Withdraw details',
      'Payment Method': 'Payment Method',
      'Withdraw details': 'Withdraw details',
      'Description': 'Description',
      'Reference Number': 'Reference Number',
      'Total': 'Total',
      'Enter pin': 'Enter pin',
      'from': 'from',
      'out of stock': 'out of stock',
      'Price bands': 'Price bands',
      'enter quantity': 'enter quantity',
      'select your id type': 'select your id type',
      'Select your ID type': 'Select your ID type',
      'ID Type': 'ID Type',
      'Step': 'Step',
      'of': 'of',
      'Select Id Type': 'Select Id Type',
      'id Number': 'id Number',
      'Enter Id Number': 'Enter Id Number',
      'Upload Photo': 'Upload Photo',
      'please enter a valid phone number': 'please enter a valid phone number',
      'Enter Verification Code': 'Enter Verification Code',
      'Enter the 6-digit code we just texted to your mobile number': 'Enter the 6-digit code we just texted to your mobile number',
      'Enter Your Otp Code': 'Enter Your Otp Code',
      'Otp Code': 'Otp Code',
      'Resend': 'Resend',
      'Please enter a valid pin with 6 digits': 'Please enter a valid pin with 6 digits',
      'Enter your new pin': 'Enter your new pin',
      'Create your pin': 'Create your pin',
      'Take a selfie': 'Take a selfie',
      'Register': 'Register',
      'You have successfully created an account, your account is pending verification, will be notified shortly': 'You have successfully created an account, your account is pending verification, will be notified shortly',
      'Success!': 'Success!',
      'Go back to Login': 'Go back to Login',
      'Subtotal': 'Subtotal',
      'VAT': 'VAT',
      'Complete Order': 'Complete Order',
      'Don’t have an account yet?': 'Don’t have an account yet?',
      'Log in or create an account': 'Log in or create an account',
      'Reset Your Password': 'Reset Your Password',
      'Is the ID easy to read ?': 'Is the ID easy to read ?',
      'Please make sure the text is clear and your entire card is visible': 'Please make sure the text is clear and your entire card is visible',
      'Yes, looks good': 'Yes, looks good',
      'Finally, Snap a selfie!': 'Finally, Snap a selfie!',
      "Take a picture of yourself. If you wear glasses, make sure there's no glare": "Take a picture of yourself. If you wear glasses, make sure there's no glare",
      'Referral code': 'Referral code',
      'Got a referral code from a friend? Enter it here. (optional)': 'Got a referral code from a friend? Enter it here. (optional)',
      'Enter Your referral code': 'Enter Your referral code',
      'Active': 'Active',
      'Complete': 'Complete',
      'No challenges found': 'No challenges found',
      'OTP code will be sent to your phone number for verification when reseting pin': 'OTP code will be sent to your phone number for verification when reseting pin',
      'token': 'token',
      'Confirm Pin': 'Confirm Pin',
      'Physical Address': 'Physical Address',
      'Retake': 'Retake',
      'Take Selfie': 'Take Selfie',
      'Role': 'Role',
      'Logging in': 'Logging in',
      'Verifying phone number': 'Verifying phone number',
      'Creating Account': 'Creating Account',
      'Creating outlet': 'Creating outlet',
      'Searching for an outlet': 'Searching for an outlet',
      'Approving withdraw': 'Approving withdraw',
      'Editing Profile': 'Editing Profile',
      'Resetting password': 'Resetting password',
      'Accepting Challenge': 'Accepting Challenge',
      'Password': 'Password',
      "Don't have an account?": "Don't have an account?",
      'Sign up': 'Sign up',
      'Enter mobile phone to verify': 'Enter mobile phone to verify',
      'Enter verification code to verify': 'Enter verification code to verify',
      'Name': 'Name',
      'address': 'address',
      'age': 'age',
      'password': 'password',
      'Verification code': 'Verification code',
      'Choose your Organization': 'Choose your Organization',
      'Choose Organization': 'Choose Organization',
      'Choose your Category': 'Choose your Category',
      'Get a Ticket': 'Get a Ticket',
      'Scan Qr Code to get Ticket': 'Scan Qr Code to get Ticket',
      'Book an Appointment': 'Book an Appointment',
      'Choose your doctor': 'Choose your Doctor',
      'Choose your date of appointment': 'Choose your date of appointment',
      'Choose your time slot': 'Choose your time slot',
      'Click the button to Upload Insurance ID Photo': 'Click the button to Upload Insurance ID Photo',
      'Upload different Insurance  Photo': 'Upload different Insurance ID photo',
      'Upload Insurance ID Photo': 'Upload Insurance ID Photo',
      'Take different Insurance ID photo from camera': 'Take different Insurance ID photo from camera',
      'Take Insurance ID photo from camera': 'Take Insurance ID photo from camera',
      'Submit Insurance ID Photo': 'Submit Insurance ID Photo',
      'Non insurance client': 'Non insurance client',
      'or': 'or',
      'Insurance client': 'Insurance client',
      'You have successfully created a ticket': 'You have successfully created a ticket',
      'Appointment No': 'Appointment No',
      'Booked at': 'Booked at',
      'Payment status: Pending': 'Payment status: Pending',
      'Please pay for your appointment using the following payment methods': 'Please pay for your appointment using the following payment methods',
      'Download ticket': 'Download ticket',
      'Go back': 'Go back',
      "Go to": "Go to",
      "Quickline qrcode": "Quickline qrcode",
      "and scan the QR code": "and scan the QR code",
      "OK. Got it!": "OK. Got it!",
      "Get time slots for this date": "Get time slots for this date",
      "Created at": "Created at",
      "Booked for": "Booked for",
      "Arrived": "Arrived",
      "Please pay for your ticket using the following payment methods": "Please pay for your ticket using the following payment methods",
      "Please choose a valid date": "Please choose a valid date",
      "Name": "Name",
      "You have created your ticket at:": "You have created your ticket at:",
      "Department": "Department",
      "Organization": "Organization",
      "Ticket No.": "Ticket No.",
      "Queue No.": "Queue No.",
      "Please pay for your appointment to reschedule": "Please pay for your appointment to reschedule",
      "If you want to reschedule your appointment please click the appointment button from the bottom right of the app": "If you want to reschedule your appointment please click the appointment button from the bottom right of the app",
      "Information": "Information",
      "Arrived:": "Arrived:",
      'Payment status:': 'Payment status:',
      'not_paid': 'pending',
      'paid': 'paid',
      'Fetching': 'Fetching',
      'Loading': 'Loading',
      'Submitting': 'Submitting',
      'Getting active slots': 'Getting active slots',
      'Fetching organizations': 'Fetching organizations',
      'Fetching categories': 'Fetching categories',
      'Uploading photo': 'Uploading photo',
      'Sumbitting request': 'Sumbitting request',
      'Downloading pdf': 'Downloading pdf',
      'Submitting appointment': 'Submitting appointment',
      'Fetching appointments': 'Fetching appointments',
      'Choose your Insurance': 'Choose your Insurance',
      'Fetching insurance': 'Fetching insurance',
      'Book a Ticket': 'Book a Ticket',
      'Insurance Verification is pending': 'Insurance Verification is pending',
      'Payment status: Insurance Verification is pending': 'Payment status: Insurance Verification is pending',
      "Experiencing issues with our app? Call our support hotline at +255 753 909 090. We're here to assist you 24/7!": "Experiencing issues with our app? Call our support hotline at +255 753 909 090. We're here to assist you 24/7!",
      "Choose Category": "Choose Category",
      "Do you have Insurance?": "Do you have Insurance?",
      "Choose Insurance": "Choose Insurance",
      "Yes": "Yes",
      "No": "No",
      'Book Appointment': 'Book Appointment',
      "Log Out": "Log Out",
      "Visits": "Visits",
      "Recent Appointments": "Recent Appointments",
      "Virtual Consultation": "Virtual Consultation"
    },
  },
  sw: {
    translation: {
      "Virtual Consultation": "Video -Tiba",
        "Recent Appointments": "Apointmenti za karibuni",
      "Visits": "Mahudhurio",
        "Log Out": "Kuondoka",
      'Book Appointment': 'Apointmenti',
        "Yes": "Ndio",
      "No": "Hapana",
       "Choose Insurance": "Chagua Bima",
      "Do you have Insurance?": "Una Bima?",
      "Choose Category": "Chagua Idara",
      "Experiencing issues with our app? Call our support hotline at +255 753 909 090. We're here to assist you 24/7!": "Unapopata shida na programu yetu? Piga simu kwa msaada wetu kwenye namba +255 753 909 090. Tupo hapa kukusaidia saa zote!",
      'Payment status: Insurance Verification is pending': 'Hali ya malipo: Uthibitishaji wa Bima unasubiri',
      'Insurance Verification is pending': 'Uthibitishaji wa Bima unasubiri',
      'Book a Ticket': 'Kata Tiketi',
      'Fetching insurance': 'Inaleta bima',
      'Choose your Insurance': 'Chagua bima yako',
      'Fetching appointments': 'Inapakua apointmenti',
      'Submitting appointment': 'Inawasilisha apointmenti',
      'Downloading pdf': 'Inapakua pdf',
      'Sumbitting request': 'Inawasilisha ombi',
      'Uploading photo': 'Inapakia picha',
      'Fetching categories': 'Inaleta kategoria',
      'Fetching organizations': 'Inaleta Taasisi',
      'Getting active slots': 'Inaleta muda unaopatikana',
      'Submitting': 'wasilisha',
      'Loading': 'inatafuta',
      'Fetching': 'Inapakua',
      "Arrived:": "umekata tiketi",
      "Information": "Taarifa",
      "If you want to reschedule your appointment please click the appointment button from the bottom right of the app": "Ikiwa unataka kubadili tarehe ya appointmenti yako tafadhali bonyeza kitufe cha appointmenti kutoka sehemu ya chini kulia ya programu",
      "Please pay for your appointment to reschedule": "Tafadhali lipia apointmenti yako kubadili tarehe ya apointimenti",
      "Queue No.": "Foleni Nambari.",
      "Ticket No.": "Nambari ya tiketi",
      "Organization": "Taasisi",
      "Department": "Idara",
      "You have created your ticket at:": "Umesajili tiketi yako tarehe:",
      "Name": "Jina",
      "Please choose a valid date": "Tafadhali chagua tarehe sahihi",
      "Please pay for your ticket using the following payment methods": "Tafadhali lipia tiketi yako kwa kutumia njia zifuatazo za malipo",
      "Arrived": "Imehifadhiwa",
      "Booked for": "Imehifadhiwa kwa",
      "Created at": "Imehifadhiwa saa",
      "Get time slots for this date": "Chagua muda wa kuhudumiwa",
      "Go to": "Nenda kwenye",
      "Quickline qrcode": "msimbo wa qr wa Quickline",
      "and scan the QR code": "na uchanganue msimbo wa QR",
      "OK. Got it!": "SAWA. Nimeelewa!",
        'You have successfully created a ticket': 'Hongera umefanikiwa kusajili tiketi',
        'Appointment No': 'Nambari ya Apointmenti',
        'Booked at': 'Imehifadhiwa saa',
        'Payment status: Pending': 'Hali ya malipo: Inasubiri',
        'Payment status:': 'Hali ya malipo:',
        'not_paid': 'inasubiri',
        'paid': 'imelipiwa',
        'Please pay for your appointment using the following payment methods': 'Tafadhali lipia apointmenti yako kwa kutumia njia zifuatazo za malipo',
        'Download ticket': 'Pakua tiketi',
        'Go back': 'Rudi nyuma',
        'Non insurance client': 'Mteja asiye na bima',
        'or': 'au',
        'Insurance client': 'Mteja wa bima',
        'Submit Insurance ID Photo': 'Wasilisha Picha ya Bima',
        'Take Insurance ID photo from camera': 'Tumia simu yako kupiga picha ya bima',
        'Take different Insurance ID photo from camera': 'Piga picha Tofauti ya Bima na kamera',
        'Upload Insurance ID Photo': 'Weka Picha ya Bima',
        'Upload different Insurance ID photo': 'Weka Picha ya Bima tofauti',
        'Click the button to Upload Insurance ID Photo': 'Bofya kitufe ili kuweka Picha ya Bima',
        'Choose your time slot': 'Chagua muda wa kuhudumiwa ',
        'Choose your date of appointment': 'Chagua tarehe ya apointmenti yako',
        'Choose your doctor': 'Chagua Daktari wako',
        'Book an Appointment': 'Weka Apointmenti',
        'Scan Qr Code to get Ticket': 'Changanua Msimbo wa Qr ili kupata tiketi',
        'Get a Ticket': 'Pata Tiketi',
        'Choose your Category': 'Chagua kategoria yako',
        'Choose your Organization': 'Chagua Taasisi',
        'Choose Organization': 'Chagua taasisi',
      'Verification code': 'namba ya uthibitisho',
      'Name': 'Jina',
      'address': 'anuani',
      'age': 'umri',
      'Enter verification code to verify': 'Ingiza namba ya uthibitisho kuthibitisha',
      'Enter mobile phone to verify': 'Weka namba ya simu kuhakiki',
      'Sign up': 'Jiunge',
      "Don't have an account?": "Huna Akaunti?",
      'Password': 'Neno la siri',
      'password': 'Neno la siri',
      'Enter Client details to Log in': 'Weka Taarifa za mteja kuingia',
      'Logging in': 'Subiri kidogo',
      'Verifying phone number': 'Subiri kidogo',
      'Creating Account': 'Subiri kidogo',
      'Creating outlet': 'Subiri kidogo',
      'Searching for an outlet': 'Subiri kidogo',
      'Approving withdraw': 'Subiri kidogo',
      'Editing Profile': 'Subiri kidogo',
      'Resetting password': 'Subiri kidogo',
      'Accepting Challenge': 'Subiri kidogo',
      'Role': 'Jukumu',
      'Take Selfie': 'Chukua Selfie',
      'ID Type': 'Aina ya Kitambulisho',
      'Retake': 'Chukua tena',
      'Physical Address': 'Anuani',
      'token': 'tokeni',
      'Confirm Pin': 'Thibitisha pin',
      'OTP code will be sent to your phone number for verification when reseting pin': 'Msimbo wa OTP utatumwa kwa nambari yako ya simu kwa uthibitisho wakati wa kuweka upya pin',
      'No challenges found': 'Hakuna Mazoezi yaliyopatikana',
      'Active': 'Hai',
      'Complete': 'Imeisha',
      'Enter Your referral code': 'Weka msimbo wako wa rufaa',
      'Got a referral code from a friend? Enter it here. (optional)': 'Je, una msimbo wa rufaa kutoka kwa rafiki? Ingiza hapa. (hiari)',
      'Referral code': 'Msimbo wa rufaa',
      "Take a picture of yourself. If you wear glasses, make sure there's no glare": 'Piga picha yako mwenyewe. Ikiwa unavaa miwani, hakikisha kuwa hakuna mwangaza',
      'Finally, Snap a selfie!': 'Hatimaye, jipige selfie!',
      'Yes, looks good': 'Ndiyo, inaonekana vizuri',
      'Please make sure the text is clear and your entire card is visible': 'Tafadhali hakikisha kuwa maandishi yako wazi na kadi yako yote inaonekana',
      'Is the ID easy to read ?': 'Je, kitambulisho ni rahisi kusoma?',
      'Enter Quantity': 'Ingiza Kiasi',
      'Reset Your Password': 'Weka upya Pin yako',
      'Login': 'Ingia',
      'Log in or create an account': 'Ingia au fungua akaunti',
      'Phone number': 'Namba ya simu',
      'Phone Number': 'Namba ya simu',
      'Enter Phone Number': 'Ingiza namba ya simu',
      'Pin': 'Namba ya utambulisho',
      'Forgot Pin?': 'Umesahau Pin?',
      'Enter Your Pin': 'Weka namba yako ya utambulisho',
      'Show': 'Onyesha',
      'Hide': 'Ficha',
      'Don`t have an account yet? Sign up': 'Bado huna akaunti? Jisajili',
      'Sign Up': 'Jisajili',
      'Create Your Account': 'Tengeneza akaunti yako',
      'Enter your mobile number so we can text you a verification code.': 'Ingiza namba ya simu ili tukutumie namba ya uthibitisho',
      'Continue': 'Endelea',
      'Available Balance': 'Kiasi kilichopo',
      'Challenges': 'Mazoezi',
      'Coins': 'Sarafu',
      'Special Offers': 'Ofa maalumu',
      'My Challenges': 'Mazoezi Yangu',
      'See All': 'Ona Yote',
      'Visit Marketplace for challenges': 'Tembelea soko kwa mazoezi',
      'Profile': 'Wasifu',
      'Leaderboard': 'Ubao wa msimamo',
      'Activity History': 'Historia ya shughuli',
      'Activities': 'Shughuli',
      'How it Works': 'Jinsi inavyofanya kazi',
      'Support': 'Msaada',
      'Privacy Policy': 'Sera ya faragha',
      'Terms and Conditions': 'Vigezo na Masharti',
      'Language': 'Lugha',
      'Sales Representative': 'Mwakilishi wa mauzo',
      'Sales': 'Mauzo',
      'Representative': 'Mwakilishi',
      'Your Rewards': 'Malipo yako',
      'Personal Info': 'Taarifa binafsi',
      'Help Center': 'Kituo cha msaada',
      'Edit Profile': 'Hariri wasifu',
      'Reset Pin': 'Weka upya namba ya utambulisho',
      'Delete Account': 'Futa Akaunti',
      'Log out': 'Toka',
      'Gender': 'Jinsia',
      'Male': 'Kiume',
      'Female': 'Kike',
      'First Name': 'Jina la kwanza',
      'Last Name': 'Jina la Mwisho',
      'Username': 'Jina la mtumiaji',
      'Date of birth': 'tarehe ya kuzaliwa',
      'Submit': 'Wasilisha',
      'Alert': 'Tahadhari',
      'are you sure you want to delete your account?': 'Una uhakika unataka kufuta akaunti yako?',
      'how it works': 'Jinsi inavyofanya kazi',
      'all': 'zote',
      'product orders': 'Maagizo ya bidhaa',
      'Shop Registration': 'Usajili wa duka',
      'Marketplace': 'Soko',
      'Accept Challenge': 'Kubali Zoezi',
      'Expired': 'Muda wake umekwisha',
      'Earn': 'Pata malipo',
      'points': 'Alama',
      'Time': 'Muda wa kuhudumiwa',
      'Win': 'Shinda',
      'ABOUT THIS CHALLENGE': 'kuhusu hili zoezi',
      'product categories': 'Kategoria ya bidhaa',
      'Products': 'Bidhaa',
      'Sell Products': 'Uza Bidhaa',
      'Make an order from uza outlets': 'Agiza kutoka maduka ya uza',
      'Register Customers': 'Sajili Wateja',
      'Register outlets to uza platform': 'sajili maduka katika jukwaa la uza',
      'Make a Survey': 'Tengeneza Tafiti',
      'Make a survey to outlets': 'Tengeneza tafiti katika maduka',
      'Invite Friends': 'Karibisha Marafiki',
      'Invite users to uza platform': 'Karibisha watumiaji katika jukwaa la uza',
      'Categories': 'Kategoria',
      'Search': 'Tafuta',
      'Sort Products': 'Panga Bidhaa',
      'Featured': 'Iliyoangaziwa',
      'Low to High': 'Chini kwenda Juu',
      'High to Low': 'Juu kwenda Chini',
      'New Arrivals': 'Mpya Zilizoingia',
      'In Stock': 'Zinazopatikana',
      'Choose a package': 'Chagua kifurushi',
      'Product Description': 'Maelezo ya bidhaa',
      'Related Products': 'Bidhaa Zinazohusiana',
      'Checkout': 'Fanya malipo',
      'Select Outlet': 'Chagua Duka',
      'Cart is empty, please add items': 'Kapu ni tupu, tafadhali weka bidhaa',
      'Scan QrCode': 'Skani msimbo wa QR',
      'or': 'au',
      'Search for an Outlet': 'Tafuta Duka',
      'Enter Outlet`s Phone number': 'Ingiza namba ya simu ya duka',
      'Verify Phone number': 'Hakiki namba ya simu',
      'register customer': 'Sajili mteja',
      'outlet name': 'Jina la duka',
      'enter outlet name': 'Ingiza jina la duka',
      'outlet type': 'Aina ya duka',
      'Select from list': 'Chagua katika orodha',
      'outlet phone number': 'Namba ya simu ya duka',
      'outlet address': 'Anuani ya duka',
      'contact person name': 'Jina la mtu wa kuwasiliana nae',
      'alternative phone number': 'Namba ya simu mbadala',
      'tin number': 'Namba ya utambulisho wa mlipa kodi (TIN)',
      'enter tin number': 'Ingiza namba ya utambulisho wa mlipa kodi (TIN)',
      'comments': 'maoni',
      'enter any comments': 'ingiza maoni yoyote',
      'Select upload method': 'Changua mfumo wa kuweka picha',
      'Take a photo from camera': 'Chukua picha kwa kamera',
      'Take photo from camera': 'Chukua picha kwa kamera',
      'Choose photo from gallery': 'Chagua picha katika gallery',
      'Notifications': 'Taarifa',
      'Pending Balance': 'Salio linalosubiri',
      'Last Transaction': 'Muamala wa mwisho',
      'Withdraw': 'Toa',
      'Transaction History': 'Historia ya muamala',
      'Withdraw Options': 'Chaguzi za kutoa',
      'Amount': 'Kiasi',
      'Proceed to checkout': 'Endelea kwenye malipo',
      'requesting withdraw': 'Ombi la kutoa',
      'Enter Withdraw details': 'Weka taarifa za kutoa',
      'Payment Method': 'Mfumo wa malipo',
      'Withdraw details': 'Taarifa za kutoa',
      'Description': 'Maelezo',
      'Reference Number': 'Namba ya kumbukumbu',
      'Total': 'Jumla',
      'Enter pin': 'Ingiza namba ya utambulisho',
      'from': 'kutoka',
      'out of stock': 'Zimekwisha',
      'Price bands': 'Makundi ya bei',
      'enter quantity': 'Ingiza kiasi',
      'select your id type': 'Chagua aina ya kitambulisho chako',
      'Step': 'Hatua',
      'of': 'Ya',
      'Select Id Type': 'Chagua aina ya kitambulisho',
      'id Number': 'Namba ya kitambulisho',
      'Enter Id Number': 'ingiza namba ya kitambulisho',
      'Upload Photo': 'Weka picha',
      'please enter a valid phone number': 'Ingiza namba yako ya simu halali',
      'Enter Verification Code': 'Ingiza namba ya uthibitisho',
      'Enter the 6-digit code we just texted to your mobile number': 'Ingiza namba yenye tarakimu 6 tuliyokutumia katika ujumbe mfupi',
      'Enter Your Otp Code': 'Ingiza namba yako ya OTP',
      'Otp Code': 'Namba ya OTP',
      'Resend': 'Tuma Tena',
      'Please enter a valid pin with 6 digits': 'Tafadhali ingiza namba halali yenye tarakimu 6',
      'Enter your new pin': 'Ingiza namba yako mpya ya utambulisho',
      'Create your pin': 'Tengeneza namba yako ya utambulisho',
      'Take a selfie': 'Piga selfie',
      'Register': 'Sajili',
      'You have successfully created an account, your account is pending verification, will be notified shortly': 'Umefanikiwa kutengeneza akaunti, akaunti yako inasubiri uthibitisho, utapatiwa taarifa hivi punde',
      'Success!': 'Mafanikio!',
      'Go back to Login': 'Rudi kwenye kuingia',
      'Subtotal': 'Jumla ndogo',
      'VAT': 'VAT',
      'Complete Order': 'Kamilisha maagizo',
      'Don’t have an account yet?': 'Je, bado huna akaunti?',
      'Enter Personal Information': 'Ingiza Taarifa za Kibinafsi',
      'Enter First Name': 'Ingiza Jina la Kwanza',
      'Enter Last Name': 'Ingiza Jina la Mwisho',
      'Enter Username': 'Ingiza Jina la mtumiaji',
      'Select Date of Birth': 'Chagua Tarehe ya Kuzaliwa',
      'Enter your address': 'Weka anwani yako',
      'Select your ID type': 'Chagua aina ya kitambulisho chako',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;


